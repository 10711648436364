import type { SponsoredAdsBannerAdsPageType } from 'apis/sponsoredAdsBannerAds';
import type { PageViewPageType } from 'types/app';

export const BANNER_ADS_MAX_STYLES = 10;
export const BANNER_ADS_MAX_ADS = 1;
export const BANNER_ADS_MIN_ADS = 1;

/*
  The following width/height values are the supported sizes for the piq ads.
  They are sizes we send to the ads endpoint, based on the users viewport to request the banners at those sizes
*/

// 2 Column - Search
// 3:1 ratio
export const BANNER_ADS_MOBILE_WIDTH_2_COL = 480;
export const BANNER_ADS_MOBILE_HEIGHT_2_COL = 160;
// 8:1 ratio
export const BANNER_ADS_DESKTOP_WIDTH_2_COL = 1440;
export const BANNER_ADS_DESKTOP_HEIGHT_2_COL = 180;

// Full width - Homepage
// 3:1 ratio
export const BANNER_ADS_MOBILE_WIDTH_FULL = 480;
export const BANNER_ADS_MOBILE_HEIGHT_FULL = 160;
// 8:1 ratio
export const BANNER_ADS_DESKTOP_WIDTH_FULL = 1440;
export const BANNER_ADS_DESKTOP_HEIGHT_FULL = 180;

export const INVIEW_THRESHOLD = 0.3;

export const PAGE_TYPE_BANNER_TYPE_MAP: Record<PageViewPageType, SponsoredAdsBannerAdsPageType> = {
  search: 'SearchResults',
  homepage: 'Homepage'
};

interface BannerAdSizes {
  width: number;
  height: number;
}

interface BannerAdScreenTypes {
  mobile: BannerAdSizes;
  desktop: BannerAdSizes;
}

export const PAGE_TYPE_BANNER_SIZE_MAP: Record<PageViewPageType, BannerAdScreenTypes> = {
  search: {
    mobile: { width: BANNER_ADS_MOBILE_WIDTH_2_COL, height: BANNER_ADS_MOBILE_HEIGHT_2_COL },
    desktop: { width: BANNER_ADS_DESKTOP_WIDTH_2_COL, height: BANNER_ADS_DESKTOP_HEIGHT_2_COL }
  },
  homepage: {
    mobile: { width: BANNER_ADS_MOBILE_WIDTH_FULL, height: BANNER_ADS_MOBILE_HEIGHT_FULL },
    desktop: { width: BANNER_ADS_DESKTOP_WIDTH_FULL, height: BANNER_ADS_DESKTOP_HEIGHT_FULL }
  }
};
